:root {
  --video-gap: 20px; /* Adjust this value to increase or decrease the gap */
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: black; /* Add black background to main page */
}

.video-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: var(--video-gap) var(--video-gap) 0 var(--video-gap); /* Remove bottom padding */
  box-sizing: border-box;
}

.video-wrapper {
  width: 100%;
  max-width: calc(177.78vh - 2 * var(--video-gap)); /* 16:9 aspect ratio (100vh * 16 / 9) minus side gaps */
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  margin: auto;
}

.video-wrapper a-scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-footer {
  height: 70px;
  background-color: transparent; /* Change to transparent background */
  display: block;
  position: absolute;
  bottom:0;
  width:100%;
  align-items: center;
  justify-content: center;
}

.mute-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.mute-button.muted {
  background-color: red;
  border-color: red;
}

.participant-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.participant-button svg {
  color: black;
}

.participant-list {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 400px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  color: black;
}

.participant-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.participant-list-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.participant-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.participant-initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.participant-name {
  font-size: 16px;
}

/* Add your custom styles here */

/* Media query for screens wider than 16:9 */
@media (min-aspect-ratio: 16/9) {
  .video-wrapper {
    width: calc((100vh - 70px) * 16 / 9 - 2 * var(--video-gap));
    padding-bottom: calc(100vh - 70px - var(--video-gap)); /* Subtract footer height and top gap */
  }
}

/* Media query for screens taller than 16:9 */
@media (max-aspect-ratio: 16/9) {
  .video-wrapper {
    width: calc(100% - 2 * var(--video-gap));
    max-width: none;
    padding-bottom: 56.25%; /* Maintain aspect ratio */
  }
}

/* Add any additional styles here */

.join-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.join-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join-input {
  width: 300px;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
}

.join-input:focus {
  border-color: #007bff;
}

.join-button {
  width: 200px;
  height: 40px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.join-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.join-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* ... other styles ... */
