:root {
  --video-gap: 20px;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.main-page {
  background-color: #000;
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.video-container {
  padding: var(--video-gap) var(--video-gap) 0 var(--video-gap);
  box-sizing: border-box;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.video-wrapper {
  max-width: calc(177.78vh - 2 * var(--video-gap));
  width: 100%;
  height: 0;
  margin: auto;
  padding-bottom: 56.25%;
  position: relative;
}

.video-wrapper a-scene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-footer {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  display: block;
  position: absolute;
  bottom: 0;
}

.mute-button {
  color: #fff;
  cursor: pointer;
  z-index: 10;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.mute-button.muted {
  background-color: red;
  border-color: red;
}

.participant-button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.participant-button svg {
  color: #000;
}

.participant-list {
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.participant-list-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.participant-list-close {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
}

.participant-item {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.participant-initials {
  color: #fff;
  background-color: #007bff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 4px;
  display: flex;
}

.participant-name {
  font-size: 16px;
}

@media (aspect-ratio >= 16 / 9) {
  .video-wrapper {
    width: calc((100vh - 70px) * 16 / 9 - 2 * var(--video-gap));
    padding-bottom: calc(100vh - 70px - var(--video-gap));
  }
}

@media (aspect-ratio <= 16 / 9) {
  .video-wrapper {
    width: calc(100% - 2 * var(--video-gap));
    max-width: none;
    padding-bottom: 56.25%;
  }
}

.join-container {
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.join-form {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  display: flex;
  box-shadow: 0 4px 6px #0000001a;
}

.join-input {
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  width: 300px;
  height: 40px;
  margin-bottom: 1rem;
  padding: 0 10px;
  font-size: 16px;
  transition: border-color .3s;
}

.join-input:focus {
  border-color: #007bff;
}

.join-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  width: 200px;
  height: 40px;
  font-size: 16px;
  transition: background-color .3s;
}

.join-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.join-button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}
/*# sourceMappingURL=index.dade0aa1.css.map */
